<script>
import { defineComponent } from '@vue/composition-api';
import BaseIcon from '@/components/base/base-icon.vue';
import Logo from '@/components/logo.vue';
import { ROUTE_PAY } from '@/lib/router';
import PaymentArtifactHeader from '@/components/payment-artifact-header.vue';
import { useBrand } from '@/use/brand';
import { useRouter } from '@/use/router';

export default defineComponent({
  name: 'RegoCustomerEmailConfirm',
  components: { BaseIcon, PaymentArtifactHeader, Logo },
  props: {
    email: {
      type: String,
      required: true,
    },
    artifactDetails: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const router = useRouter();
    const brand = useBrand();
    function redirectBack() {
      router.push({ name: ROUTE_PAY, params: { artifactId: props.artifactDetails.artifactId } });
    }
    return {
      router,
      brand,
      redirectBack,
    };
  },
});
</script>

<template>
  <div>
    <div>
      <div v-if="artifactDetails" class="flex flex-col-reverse md:flex-row justify-between items-center my-10 bg">
        <span @click="redirectBack" data-testid="directBack" class="self-start md:self-auto cursor-pointer">
          <BaseIcon size="fa-md" class="back-icon" icon="fa-arrow-left"
        /></span>
        <PaymentArtifactHeader :artifactDetails="artifactDetails" class="w-11/12 justify-end" />
      </div>
      <Logo v-else class="my-10" size="small" />
      <h1 class="heading-2 mt-4">Confirm your email</h1>
    </div>
    <p class="pb-2 text-shades-darker">
      We've sent you a confirmation email to <span class="font-bold">{{ email }}</span> with a unique link to continue
      your application.
    </p>
    <figure class="my-6">
      <img src="@/assets/confirm_email.svg" alt="email sent" />
    </figure>
    <p class="pb-2 text-shades-darker">
      Please check your inbox for our email (if it's not there, try checking your Promotions or Spam folder).
    </p>
    <p class="pb-2 text-shades-darker mb-10">
      If your email does not arrive within 10 minutes, please reach out to us at
      <a class="text-primary-dark" :href="`mailto:${brand.contactEmail}`"> {{ brand.contactEmail }} </a>.
    </p>
  </div>
</template>

<style scoped>
</style>
