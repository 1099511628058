<script>
import { defineComponent, ref } from '@vue/composition-api';
import BaseButton from '@/components/base/base-button.vue';
import { useRouter } from '@/use/router';
import { useBrand } from '@/use/brand';
import { ROUTE_REGISTER_CUSTOMER_ADDRESS } from '@/lib/router';

export default defineComponent({
  name: 'RegoCustomerEmailVerification',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  components: {
    BaseButton,
  },
  setup() {
    const isLoading = ref(false);
    const router = useRouter();
    const brand = useBrand();

    async function handleNextClick() {
      try {
        isLoading.value = true;
        await router.push({ name: ROUTE_REGISTER_CUSTOMER_ADDRESS });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }

    return {
      isLoading,
      brand,
      handleNextClick,
    };
  },
});
</script>

<template>
  <div class="max-w-lg mx-auto mt-20">
    <h1 class="heading-2 md:text-left text-center">Email Confirmed</h1>
    <figure class="my-10 flex justify-center">
      <img src="@/assets/email-confirmed.svg" alt="email confirmed" />
    </figure>
    <p class="pb-2 text-sm text-shades-darker">
      Welcome to {{ brand.name }}, {{ user.firstName }}. Use this email next time to login to your account and access
      your payment plans.
    </p>
    <p class="pb-2 text-sm text-shades-darker">Next, we'll ask you for your residential history to verify you.</p>
    <div class="flex justify-center md:justify-end mt-10">
      <BaseButton secondary rounded class="md:w-52 w-full" :loading="isLoading" @click="handleNextClick"
        >Next</BaseButton
      >
    </div>
  </div>
</template>

<style scoped>
</style>
