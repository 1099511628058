<script>
import { computed, defineComponent, reactive } from '@vue/composition-api';
import BaseIcon from '@/components/base/base-icon.vue';
import BaseInput from '@/components/base/base-input.vue';
import BaseButton from '@/components/base/base-button.vue';
import BankLinkPending from './bank-link-pending.vue';
import { BANK_SYNC_COMPLETE, BANK_SYNC_PENDING } from '@/use/bank-linking';
import BankLinkSuccess from './bank-link-success.vue';
import ErrorNotification from './error-notification.vue';

export default defineComponent({
  components: {
    BaseIcon,
    BaseInput,
    BaseButton,
    BankLinkPending,
    BankLinkSuccess,
    ErrorNotification,
  },
  name: 'BankLogin',
  props: {
    bank: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isError: {
      type: Boolean,
      required: true,
    },
    bankLinkStatus: {
      type: String,
      required: true,
    },
    error: {
      required: false,
      type: Object,
      default: () => ({
        type: '',
        message: '',
        messages: [],
      }),
    },
  },
  setup(props, { emit }) {
    const form = reactive({
      username: '',
      password: '',
      secondaryLogin: '',
      securityCode: '',
    });

    const isBankLinkPending = computed(() => props.bankLinkStatus === BANK_SYNC_PENDING);
    const isBankLinkComplete = computed(() => props.bankLinkStatus === BANK_SYNC_COMPLETE);

    function formatInputMessage(message = '') {
      return `${message.toLowerCase()} is required`;
    }

    function resetForm() {
      form.username = '';
      form.password = '';
      form.secondaryLogin = '';
      form.securityCode = '';
    }

    function emitFormSubmit() {
      emit('submit', { ...form, institutionId: props.bank.id });
    }

    function emitRetryConnection() {
      emit('retry-connection');
    }

    function emitClose() {
      resetForm();
      emit('close');
    }

    return {
      form,
      formatInputMessage,
      isBankLinkPending,
      isBankLinkComplete,
      emitFormSubmit,
      emitRetryConnection,
      emitClose,
    };
  },
});
</script>

<template>
  <div class="flex flex-col justify-center">
    <div class="flex justify-end">
      <span @click="emitClose">
        <BaseIcon class="cursor-pointer" icon="fa-times" size="fa-2x" />
      </span>
    </div>
    <div class="p-10">
      <div class="flex flex-col items-center mb-3">
        <figure v-show="!isBankLinkComplete" class="bank-logo border p-2 flex justify-center items-center">
          <img class="bank-logo" :src="bank.logo.links.square" :alt="bank.name" />
        </figure>
      </div>
      <transition name="fade" mode="out-in">
        <form v-if="!isLoading" class="flex flex-col" @submit.prevent="emitFormSubmit">
          <div v-show="!isLoading" class="mb-6">
            <p class="text-center">Connect directly to</p>
            <p class="text-center font-bold">{{ bank.name }}</p>
          </div>
          <BaseInput
            class="mb-2"
            id="usernameInput"
            v-model="form.username"
            autocomplete="disable-autocomplete"
            :label="bank.loginIdCaption"
            :title="formatInputMessage(bank.loginIdCaption)"
          />
          <BaseInput
            class="mb-2"
            id="secondLoginIdInput"
            required
            autocomplete="disable-autocomplete"
            v-if="bank.secondaryLoginIdCaption"
            v-model="form.secondaryLogin"
            :label="bank.secondaryLoginIdCaption"
            :title="formatInputMessage(bank.secondaryLoginIdCaption)"
          />
          <BaseInput
            class="mb-2"
            id="passwordInput"
            required
            type="password"
            v-model="form.password"
            :label="bank.passwordCaption"
            autocomplete="new-password"
            :title="formatInputMessage(bank.passwordCaption)"
          />
          <BaseInput
            class="mb-2"
            id="securityCodeInput"
            required
            v-if="bank.securityCodeCaption"
            v-model="form.securityCode"
            :label="bank.securityCodeCaption"
            autocomplete="new-password"
            :title="formatInputMessage(bank.securityCodeCaption)"
          />
          <ErrorNotification class="mb-3" v-show="isError" :errorMessages="error" />
          <BaseButton @click="emitRetryConnection" v-if="isError">Retry Connection</BaseButton>
          <BaseButton type="submit" v-else :disabled="isLoading"> Login to Bank</BaseButton>
        </form>
        <BankLinkPending v-else-if="isLoading && isBankLinkPending" />
        <BankLinkSuccess v-else :bankName="bank.name" />
      </transition>
    </div>
  </div>
</template>

<style scoped>
.bank-logo {
  @apply h-32 w-32;
}
</style>
