<script>
import { defineComponent } from '@vue/composition-api';
import baseIcon from '@/components/base/base-icon.vue';

export default defineComponent({
  components: { baseIcon },
  name: 'BankLinkSuccess',
  props: {
    bankName: {
      type: String,
      required: true,
    },
  },
  setup() {},
});
</script>

<template>
  <div class="text-center">
    <figure class="flex justify-center">
      <img src="@/assets/check-circle-large.svg" />
    </figure>
    <h3 class="mt-20 mb-3 heading-2">Success!</h3>
    <p class="text-shades-darker">
      You've successfully logged into <span class="font-bold">{{ bankName }}</span>
    </p>
  </div>
</template>

<style scoped>
</style>
