<script>
import { computed, defineComponent, reactive, ref } from '@vue/composition-api';
import BasePrefixedInput from '@/components/base/base-prefixed-input.vue';
import BaseModal from '@/components/base/base-modal.vue';
import BaseButton from '@/components/base/base-button.vue';
import BaseIcon from '@/components/base/base-icon.vue';
import ErrorNotification from '@/components/error-notification.vue';
import { useRouter } from '@/use/router';
import { registerCustomerApi } from '@/api/registration';
import { debounce } from '@/lib/saltier';
import { ROUTE_REGISTER_CUSTOMER_ADDRESS, ROUTE_REGISTER_LINK_BANK } from '@/lib/router';
import { useApiErrors } from '@/use/errors';

export default defineComponent({
  name: 'RegoCustomerConfirmPhoneNumber',
  components: {
    BasePrefixedInput,
    ErrorNotification,
    BaseModal,
    BaseButton,
    BaseIcon,
  },
  setup() {
    const isLoading = ref(false);
    const otpValid = ref(false);
    const phoneValid = ref(false);
    const router = useRouter();
    const phoneData = reactive({
      prefix: '+61',
      phone: '',
      phoneRaw: '',
    });

    const { apiError, isError, setError, clearError } = useApiErrors();

    const verifyOtpCode = debounce(async (value) => {
      try {
        await registerCustomerApi.postVerifyOtp({ otp: value });
        otpValid.value = true;
      } catch (error) {
        otpValid.value = false;
      }
    }, 800);

    function routeToLinkBank() {
      isLoading.value = true;
      router.push({ name: ROUTE_REGISTER_LINK_BANK });
    }

    function routeToCustomerAddress() {
      router.push({ name: ROUTE_REGISTER_CUSTOMER_ADDRESS });
    }

    async function requestOtp() {
      try {
        clearError();
        isLoading.value = true;
        await registerCustomerApi.postRequestOtp({ phone: `${phoneData.prefix}${phoneData.phoneRaw}` });
        phoneValid.value = true;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        setError(error);
      } finally {
        isLoading.value = false;
      }
    }

    const renderTitle = computed(() => (phoneValid.value ? 'Verify your mobile' : 'Enter Mobile Number'));

    function setPhoneValidFalse() {
      phoneValid.value = false;
    }

    function setPhoneRawValue(value) {
      phoneData.phoneRaw = value;
    }

    return {
      isLoading,
      isError,
      apiError,
      phoneData,
      routeToLinkBank,
      otpValid,
      phoneValid,
      verifyOtpCode,
      routeToCustomerAddress,
      requestOtp,
      renderTitle,
      setPhoneValidFalse,
      setPhoneRawValue,
    };
  },
});
</script>

<template>
  <div class="max-w-xl mx-auto">
    <h1 class="heading-2 md:heading-1 text-center mb-4">{{ renderTitle }}</h1>
    <p class="pb-2 text-center text-sm text-shades-darker">
      For account security purposes, enter your mobile number and we will send you a text message with a verification
      code.
    </p>

    <template v-if="!phoneValid">
      <div class="my-8 flex justify-center">
        <div>
          <p class="font-bold mb-2">Mobile number:</p>
          <BasePrefixedInput
            variant="shades"
            v-model="phoneData.phone"
            :prefix="phoneData.prefix"
            placeholder="XXX XXX XXX"
            @input-raw="setPhoneRawValue"
            :formattingOptions="{
              numericOnly: true,
              blocks: [3, 3, 3],
            }"
          />
        </div>
      </div>
      <div class="mt-16">
        <ErrorNotification class="my-4" v-show="isError" type="is-warning" :errorMessages="apiError" />
        <div class="flex justify-between">
          <BaseButton secondary rounded outlined class="md:w-64" @click.prevent="routeToCustomerAddress"
            >Back</BaseButton
          >
          <BaseButton
            secondary
            rounded
            class="md:w-64"
            :loading="isLoading"
            :disabled="isLoading"
            @click.prevent="requestOtp"
          >
            Send Code
          </BaseButton>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="flex flex-col items-center my-8">
        <div>
          <p class="font-bold mb-2">Mobile Number</p>
          <div class="relative mb-4">
            <BasePrefixedInput
              variant="shades"
              readonly
              :value="phoneData.phone"
              prefix="+61"
              :formattingOptions="{
                numericOnly: true,
                blocks: [3, 3, 3],
              }"
            />
            <a @click="setPhoneValidFalse" class="ml-3 absolute bottom-3 left-full text-link text-sm">edit</a>
          </div>
        </div>

        <div class="relative">
          <p class="font-bold mb-2">Enter code we sent:</p>
          <BasePrefixedInput
            @input-raw="verifyOtpCode"
            variant="shades"
            label="code"
            prefix="R-"
            placeholder="Code"
            :formattingOptions="{
              numericOnly: true,
            }"
          />
          <BaseIcon
            class="text-primary ml-3 absolute bottom-3 left-full"
            v-show="otpValid"
            icon="fa-check-circle"
            size="fa-lg"
          />
          <BaseIcon
            class="text-danger-dark ml-3 absolute bottom-3 left-full"
            v-show="!otpValid"
            icon="fa-times-circle"
            size="fa-lg"
          />
        </div>
        <p class="text-center text-sm text-shades-darker my-4">
          Didn't get a code?
          <a class="resend text-primary cursor-pointer" @click="requestOtp"> Resend SMS </a>
        </p>
      </div>

      <div class="flex justify-between mt-16">
        <BaseButton secondary rounded outlined class="md:w-64" @click.prevent="routeToCustomerAddress">
          Back
        </BaseButton>

        <BaseButton
          secondary
          rounded
          class="md:w-64"
          :loading="isLoading"
          :disabled="!otpValid"
          @click.prevent="routeToLinkBank"
          >Next</BaseButton
        >
      </div>
    </template>
  </div>
</template>

<style scoped>
</style>
