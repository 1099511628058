<script>
import { v4 as uuid } from 'uuid';
import { computed, defineComponent, reactive, ref } from '@vue/composition-api';
import sub from 'date-fns/sub';
import BaseInput from '@/components/base/base-input.vue';
import BaseButton from '@/components/base/base-button.vue';
import BaseIcon from '@/components/base/base-icon.vue';
import BaseCheckbox from '@/components/base/base-checkbox.vue';
import AddressFinder from '@/components/address-finder.vue';
import ErrorNotification from '@/components/error-notification.vue';
import { registerCustomerApi } from '@/api/registration';
import { isDateInPast, parseToDate } from '@/lib/time';
import { ROUTE_REGISTER, ROUTE_REGISTER_VERIFY_PHONE } from '@/lib/router';
import { useBrand } from '@/use/brand';
import { useRouter } from '@/use/router';
import { useApiErrors } from '@/use/errors';
import { debounce } from '@/lib/saltier';
import { transformResidentialHistory } from '@/lib/registration';

export default defineComponent({
  name: 'RegoCustomerResidentialAddress',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  components: {
    BaseInput,
    BaseButton,
    ErrorNotification,
    BaseCheckbox,
    AddressFinder,
    BaseIcon,
  },
  setup() {
    const { isError, setError, clearError, apiError } = useApiErrors();
    const isLoading = ref(false);
    const router = useRouter();
    const brand = useBrand();
    const previousAddressRequired = ref(false);
    const form = reactive({
      currentAddress: {
        rawAddress: '',
        address1: '',
        address2: '',
        city: '',
        provinceOrState: '',
        provinceOrStateCode: '',
        country: '',
        countryCode: '',
        postalCode: '',
        moveInDate: '',
      },
      previousAddress: [],
    });

    function addEmptyPreviousAddress() {
      form.previousAddress.push({
        id: uuid(),
        rawAddress: '',
        address1: '',
        address2: '',
        city: '',
        provinceOrState: '',
        provinceOrStateCode: '',
        country: '',
        countryCode: '',
        postalCode: '',
        moveInDate: '',
        moveOutDate: '',
      });
    }

    function updatePreviousAddress(address, id) {
      const matchedAddress = form.previousAddress.find((el) => el.id === id);
      const index = form.previousAddress.findIndex((el) => id === el.id);
      if (!matchedAddress) {
        return;
      }
      const newAddress = { ...matchedAddress, ...address };
      form.previousAddress.splice(index, 1, newAddress);
    }

    const updateCurrentAddressMoveInDate = debounce((dateString) => {
      form.currentAddress.moveInDate = dateString;

      const dateFiveYearsAgo = sub(new Date(), {
        years: 5,
        months: 1,
      });
      const moveInDate = parseToDate(`01/${dateString}`);
      if (!isDateInPast(dateFiveYearsAgo, moveInDate)) {
        previousAddressRequired.value = false;
        return;
      }
      previousAddressRequired.value = true;
      if (form.previousAddress.length === 0) {
        addEmptyPreviousAddress();
      }
    }, 300);

    function updateCurrentAddress(address) {
      form.currentAddress.rawAddress = address.rawAddress;
      form.currentAddress.address1 = address.address1;
      form.currentAddress.address2 = address.address2;
      form.currentAddress.city = address.city;
      form.currentAddress.provinceOrState = address.provinceOrState;
      form.currentAddress.provinceOrStateCode = address.provinceOrStateCode;
      form.currentAddress.country = address.country;
      form.currentAddress.countryCode = address.countryCode;
      form.currentAddress.postalCode = address.postalCode;
    }

    function removePreviousAddress(id) {
      const index = form.previousAddress.findIndex((el) => id === el.id);
      form.previousAddress.splice(index, 1);
    }

    function routeToCreateAccount() {
      router.push({ name: ROUTE_REGISTER });
    }

    async function submitAddressHistory() {
      try {
        clearError();
        isLoading.value = true;
        const payload = transformResidentialHistory(form);
        await registerCustomerApi.postCustomerDetails(payload);
        router.push({ name: ROUTE_REGISTER_VERIFY_PHONE });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        setError(error);
      } finally {
        isLoading.value = false;
      }
    }

    const buttonDisabled = computed(() => !form.currentAddress.rawAddress || !updateCurrentAddressMoveInDate);
    return {
      isLoading,
      isError,
      apiError,
      form,
      buttonDisabled,
      updateCurrentAddressMoveInDate,
      previousAddressRequired,
      brand,
      submitAddressHistory,
      updateCurrentAddress,
      updatePreviousAddress,
      removePreviousAddress,
      addEmptyPreviousAddress,
      routeToCreateAccount,
    };
  },
});
</script>

<template>
  <div class="flex flex-col max-w-lg mx-auto">
    <div class="relative">
      <a @click="routeToCreateAccount">
        <BaseIcon class="absolute top-1/3 cursor-pointer md:hidden" icon="fa-arrow-left" size="fa-lg" />
      </a>
      <h1 class="heading-2 md:heading-1 text-center">Residential History</h1>
    </div>
    <p class="py-4 text-sm text-shades-darker">
      We need to collect some residential history information to verify your identity.
    </p>
    <p class="font-semibold">Current Address</p>
    <form class="my-3 flex flex-col" @submit.prevent="submitAddressHistory">
      <AddressFinder
        class="mb-1"
        id="currentAddress"
        required
        @address-selected="updateCurrentAddress"
        name="currentAddress"
        v-model="form.currentAddress.rawAddress"
        label="Home address"
        title="Please enter your current home address"
      />
      <BaseInput
        required
        id="currentAddressMoveInDateInput"
        :value="form.currentAddress.moveInDate"
        @input="updateCurrentAddressMoveInDate"
        label="Move-In Date"
        icon="fa-calendar-alt"
        title="Please enter your current address move in date"
        :placeholder="'MM/YYYY'"
        :formattingOptions="{
          date: true,
          datePattern: ['m', 'Y'],
        }"
      />
      <div class="flex flex-col" v-show="previousAddressRequired">
        <p class="font-semibold">Previous Addresses</p>
        <p class="pb-2 text-xs text-shades-darker max-w-md">
          Looks like you have lived at your current home for less than five years. Please add in your previous
          address/es for up to five years.
        </p>
        <div v-for="address in form.previousAddress" :key="address.id">
          <div class="relative">
            <a
              class="text-danger cursor-pointer absolute -top-1 right-0 text-sm"
              @click="removePreviousAddress(address.id)"
            >
              remove
            </a>
            <AddressFinder
              :id="address.id"
              class="mt-4 w-full"
              @address-selected="updatePreviousAddress"
              v-model="address.rawAddress"
              label="Home address"
            />
          </div>
          <div class="flex flex-col justify-between md:flex-row">
            <BaseInput
              :id="`previousMoveInDate${address.id}`"
              class="flex-grow md:mr-2"
              v-model="address.moveInDate"
              label="Move-In Date"
              icon="fa-calendar-alt"
              :placeholder="'MM/YYYY'"
              :formattingOptions="{
                date: true,
                datePattern: ['m', 'Y'],
              }"
            />
            <BaseInput
              :id="`previousMoveOutDate${address.id}`"
              class="flex-grow"
              v-model="address.moveOutDate"
              label="Move-Out Date"
              icon="fa-calendar-alt"
              :placeholder="'MM/YYYY'"
              :formattingOptions="{
                date: true,
                datePattern: ['m', 'Y'],
              }"
            />
          </div>
        </div>
        <BaseButton @click.prevent="addEmptyPreviousAddress" class="w-full my-4" outlined rounded>
          <BaseIcon class="mr-2" icon="fa-plus" />
          <span class="font-normal">Add another address</span>
        </BaseButton>
      </div>
      <ErrorNotification v-show="isError" type="is-warning" :errorMessages="apiError" />
      <div class="flex justify-between mt-8">
        <BaseButton class="hidden md:block w-48" secondary outlined rounded @click="routeToCreateAccount">
          Back
        </BaseButton>
        <BaseButton class="w-full md:w-48" primary rounded :disabled="buttonDisabled"> Confirm </BaseButton>
      </div>
    </form>
  </div>
</template>
<style scoped>
</style>
