var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"max-w-xl mx-auto"},[_c('h1',{staticClass:"heading-2 md:heading-1 text-center mb-4"},[_vm._v(_vm._s(_vm.renderTitle))]),_c('p',{staticClass:"pb-2 text-center text-sm text-shades-darker"},[_vm._v(" For account security purposes, enter your mobile number and we will send you a text message with a verification code. ")]),(!_vm.phoneValid)?[_c('div',{staticClass:"my-8 flex justify-center"},[_c('div',[_c('p',{staticClass:"font-bold mb-2"},[_vm._v("Mobile number:")]),_c('BasePrefixedInput',{attrs:{"variant":"shades","prefix":_vm.phoneData.prefix,"placeholder":"XXX XXX XXX","formattingOptions":{
            numericOnly: true,
            blocks: [3, 3, 3],
          }},on:{"input-raw":_vm.setPhoneRawValue},model:{value:(_vm.phoneData.phone),callback:function ($$v) {_vm.$set(_vm.phoneData, "phone", $$v)},expression:"phoneData.phone"}})],1)]),_c('div',{staticClass:"mt-16"},[_c('ErrorNotification',{directives:[{name:"show",rawName:"v-show",value:(_vm.isError),expression:"isError"}],staticClass:"my-4",attrs:{"type":"is-warning","errorMessages":_vm.apiError}}),_c('div',{staticClass:"flex justify-between"},[_c('BaseButton',{staticClass:"md:w-64",attrs:{"secondary":"","rounded":"","outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.routeToCustomerAddress.apply(null, arguments)}}},[_vm._v("Back")]),_c('BaseButton',{staticClass:"md:w-64",attrs:{"secondary":"","rounded":"","loading":_vm.isLoading,"disabled":_vm.isLoading},on:{"click":function($event){$event.preventDefault();return _vm.requestOtp.apply(null, arguments)}}},[_vm._v(" Send Code ")])],1)],1)]:[_c('div',{staticClass:"flex flex-col items-center my-8"},[_c('div',[_c('p',{staticClass:"font-bold mb-2"},[_vm._v("Mobile Number")]),_c('div',{staticClass:"relative mb-4"},[_c('BasePrefixedInput',{attrs:{"variant":"shades","readonly":"","value":_vm.phoneData.phone,"prefix":"+61","formattingOptions":{
              numericOnly: true,
              blocks: [3, 3, 3],
            }}}),_c('a',{staticClass:"ml-3 absolute bottom-3 left-full text-link text-sm",on:{"click":_vm.setPhoneValidFalse}},[_vm._v("edit")])],1)]),_c('div',{staticClass:"relative"},[_c('p',{staticClass:"font-bold mb-2"},[_vm._v("Enter code we sent:")]),_c('BasePrefixedInput',{attrs:{"variant":"shades","label":"code","prefix":"R-","placeholder":"Code","formattingOptions":{
            numericOnly: true,
          }},on:{"input-raw":_vm.verifyOtpCode}}),_c('BaseIcon',{directives:[{name:"show",rawName:"v-show",value:(_vm.otpValid),expression:"otpValid"}],staticClass:"text-primary ml-3 absolute bottom-3 left-full",attrs:{"icon":"fa-check-circle","size":"fa-lg"}}),_c('BaseIcon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.otpValid),expression:"!otpValid"}],staticClass:"text-danger-dark ml-3 absolute bottom-3 left-full",attrs:{"icon":"fa-times-circle","size":"fa-lg"}})],1),_c('p',{staticClass:"text-center text-sm text-shades-darker my-4"},[_vm._v(" Didn't get a code? "),_c('a',{staticClass:"resend text-primary cursor-pointer",on:{"click":_vm.requestOtp}},[_vm._v(" Resend SMS ")])])]),_c('div',{staticClass:"flex justify-between mt-16"},[_c('BaseButton',{staticClass:"md:w-64",attrs:{"secondary":"","rounded":"","outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.routeToCustomerAddress.apply(null, arguments)}}},[_vm._v(" Back ")]),_c('BaseButton',{staticClass:"md:w-64",attrs:{"secondary":"","rounded":"","loading":_vm.isLoading,"disabled":!_vm.otpValid},on:{"click":function($event){$event.preventDefault();return _vm.routeToLinkBank.apply(null, arguments)}}},[_vm._v("Next")])],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }