import { ref } from '@vue/composition-api';
import { registerCustomerApi } from '@/api/registration';
import { ERROR_REQUEST_TIMEOUT, ERROR_BAD_CREDS } from '@/lib/api';
import { useApiErrors } from './errors';

export const BANK_STATUS_OPERATIONAL = 'operational';
export const BANK_STATUS_PARTIAL_OUTAGE = 'partial-outage';
export const BANK_STATUS_MAJOR_OUTAGE = 'major-outage';
export const BANK_STATUS_DEGRADED = 'degraded-performance';
export const BANK_SYNC_COMPLETE = 'Complete';
export const BANK_SYNC_FAILED = 'Failed';
export const BANK_SYNC_PENDING = 'Pending';

const TEMPLATE_CONNECTION_TIMEOUT = `The secure connection to your bank has expired,
we can establish a new connection and attempt to connect your account.`;

const TEMPLATE_BAD_CREDS = `We couldn't connect to your bank with the provided credentials.
Please check your login information and ensure that your bank has no pending actions that could block your login.`;

const TEMPLATE_GENERIC = 'There was an issue linking your bank';

/**
 * returns bank linking error templates
 * @param {Error} err error based on message payload
 * @returns {string}
 */
function renderBankLinkErrorMessage(err) {
  const { status } = err?.response;
  const repo = {
    [ERROR_REQUEST_TIMEOUT]: TEMPLATE_CONNECTION_TIMEOUT,
    [ERROR_BAD_CREDS]: TEMPLATE_BAD_CREDS,
  };
  if (repo[status] === undefined) {
    return TEMPLATE_GENERIC;
  }
  return repo[status];
}

/**
 * Helper util to handle the complex workflow of bank linking and polling for account sync.
 * @returns
 */
export default function useBankLinking() {
  let bankSyncHandler;
  const syncStatus = ref(BANK_SYNC_PENDING);
  const isLoading = ref(false);

  const { apiError, isError, setError, clearError } = useApiErrors(renderBankLinkErrorMessage, TEMPLATE_GENERIC);

  async function waitForSyncToComplete() {
    try {
      isLoading.value = true;
      const { data } = await registerCustomerApi.getLinkBankStatus();
      if (data.status === BANK_SYNC_FAILED) {
        throw Error('Account sync failure');
      }
      if (data.status === BANK_SYNC_COMPLETE) {
        syncStatus.value = BANK_SYNC_COMPLETE;
        clearInterval(bankSyncHandler);
        return;
      }
    } catch (error) {
      setError(error);
      isLoading.value = false;
      clearInterval(bankSyncHandler);
    }
  }

  async function submitBankLogin(bankForm) {
    try {
      isLoading.value = true;
      clearError();
      await registerCustomerApi.postLinkBank(bankForm);
      bankSyncHandler = setInterval(waitForSyncToComplete, 2500);
    } catch (error) {
      setError(error);
      isLoading.value = false;
    }
  }

  return {
    syncStatus,
    isLoading,
    apiError,
    isError,
    clearError,
    submitBankLogin,
  };
}
