<script>
import { defineComponent } from '@vue/composition-api';
import { formatCurrency } from '@/lib/formats';

export default defineComponent({
  name: 'SelectAccountCard',
  props: {
    account: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    function emitClick() {
      emit('click', props.account.token);
    }
    return {
      formatCurrency,
      emitClick,
    };
  },
});
</script>

<template>
  <div
    @click="emitClick"
    :class="[
      'inline-flex cursor-pointer border border-shades p-4 rounded-lg w-full max-w-lg select-account-card',
      { selected },
    ]"
  >
    <div class="pr-3">
      <label class="relative cursor-pointer">
        <input class="hidden peer" type="radio" @input="emitClick" :checked="selected" />
        <span class="w-7 h-7 inline-block rounded-full border-2 border-grey flex-no-shrink"></span>
        <span
          class="absolute left-1.5 bottom-2.5 peer-checked:bg-primary w-4 h-4 inline-block rounded-full flex-no-shrink"
        />
      </label>
    </div>
    <div class="flex justify-between w-full">
      <div class="flex flex-col">
        <span class="font-bold">{{ account.name }}</span>
        <span class="text-shades-dark text-sm"> Account number: {{ account.account }} </span>
        <span class="text-shades-dark text-sm"> BSB number: {{ account.bsb }} </span>
      </div>
      <div class="flex flex-col text-right">
        <h3 class="font-bold">{{ formatCurrency(account.balance) }}</h3>
        <span class="text-shades-dark text-sm"> available balance </span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.select-account-card.selected {
  @apply border-primary;
}
</style>
