<script>
import { defineComponent, ref } from '@vue/composition-api';
import { useInputs } from '@/use/inputs';
import { PRIMARY, variants } from '@/lib/variants';

export default defineComponent({
  inheritAttrs: false,
  name: 'BasePrefixedInput',
  components: {},
  props: {
    /**
     * Prefix to show in the input
     */
    prefix: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: false,
      default: PRIMARY,
      validation: (value) => Object.values(variants()).includes(value),
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * FormattingOptions prop allows a variety of cleave.js options for formatting inputs
     * https://nosir.github.io/cleave.js/
     * https://github.com/nosir/cleave.js/blob/master/doc/options.md#date
     * example below:
     * :formattingOptions="{
     * date: true,
     * datePattern: ['d','m','Y']
     * }"
     * this example sets a date format and provides the equivalent input format
     * see the documentation link above for examples of phone and credit card formatting
     */
    formattingOptions: {
      type: Object,
      required: false,
    },
  },
  setup(props, { emit }) {
    const prefixInput = ref(null);

    function stripPrefix(value, prefix) {
      if (value === '' || prefix === '') {
        return value;
      }
      if (value.startsWith(prefix)) {
        return value.replaceAll(prefix, '');
      }
      return value;
    }

    const { onFocus, onBlur, valid } = useInputs({ emit, inputRef: prefixInput });

    function emitUpdate(event) {
      if (prefixInput?.value?.cleave) {
        const rawValue = prefixInput?.value?.cleave?.getRawValue();
        emit('input-raw', stripPrefix(rawValue, props.prefix));
      }
      emit('input', stripPrefix(event?.target?.value, props.prefix));
    }

    return {
      emitUpdate,
      onFocus,
      onBlur,
      valid,
      prefixInput,
    };
  },
});
</script>

<template>
  <div class="relative">
    <input
      ref="prefixInput"
      v-cleave="formattingOptions"
      @focus="onFocus"
      @blur="onBlur"
      :class="[
        `peer
        p-2
        border
        rounded
        rounded-l-none border-l-0
        focus:outline-none

        focus:border-transparent
        ml-12
        `,
        variant,
      ]"
      v-bind="$attrs"
      :value="value"
      @input="emitUpdate"
    />
    <span
      :class="[
        `
        absolute left-0
        p-2
        text-center
        rounded-l
        border
        border-r-none
        w-12
        `,
        'input-prefix',
        variant,
      ]"
    >
      {{ prefix }}
    </span>
  </div>
</template>

<style scoped>
input {
  @apply focus:ring-1 border-shades read-only:bg-shades-lighter read-only:border-none;
}

.input-prefix {
  @apply peer-focus:ring-1 bg-primary border-primary peer-read-only:bg-shades peer-read-only:border-none;
}
input.primary {
  @apply focus:ring-primary read-only:ring-0;
}
.input-prefix.primary {
  @apply bg-primary border-primary peer-focus:ring-primary peer-focus:border-primary text-white peer-read-only:ring-0;
}
input.accent {
  @apply focus:ring-accent read-only:ring-0;
}
.input-prefix.accent {
  @apply bg-accent border-accent peer-focus:ring-accent  text-white peer-read-only:ring-0;
}
input.shades {
  @apply focus:ring-shades read-only:ring-0;
}
.input-prefix.shades {
  @apply bg-shades
  border-shades
  peer-focus:ring-shades
  peer-focus:border-shades
  peer-read-only:ring-0
  text-white;
}
input.dark {
  @apply focus:ring-dark read-only:ring-0;
}
.input-prefix.dark {
  @apply bg-dark
  border-dark
  peer-focus:ring-dark
  peer-focus:border-dark
  peer-read-only:ring-0
  text-white;
}
input.success {
  @apply focus:ring-success read-only:ring-0;
}
.input-prefix.success {
  @apply bg-success
  border-success
  peer-focus:ring-success
  peer-focus:border-success
  peer-read-only:ring-0
  text-white;
}
input.warning {
  @apply focus:ring-warning read-only:ring-0;
}
.input-prefix.warning {
  @apply bg-warning
  border-warning
  peer-focus:ring-warning
  peer-focus:border-warning
  peer-read-only:ring-0
  text-white;
}
input.danger {
  @apply focus:ring-danger read-only:ring-0;
}
.input-prefix.danger {
  @apply bg-danger
  border-danger
  peer-focus:ring-danger
  peer-focus:border-danger
  peer-read-only:ring-0
  text-white;
}
</style>
