<script>
import { computed, defineComponent, onMounted, onUnmounted, ref } from '@vue/composition-api';
import baseIcon from './base/base-icon.vue';

export default defineComponent({
  components: { baseIcon },
  name: 'BankLinkPending',
  setup() {
    let messageHandler;
    const intervalMs = 10000;
    const messages = ['Connecting to Bank...', 'Verifying details...', 'Retrieving your bank details...'];
    const index = ref(0);
    const message = computed(() => messages[index.value]);

    function incrementMessage() {
      const newIndex = index.value + 1;
      if (newIndex >= messages.length) {
        return;
      }
      index.value += 1;
    }

    onMounted(() => {
      messageHandler = setInterval(incrementMessage, intervalMs);
    });

    onUnmounted(() => {
      index.value = 0;
      clearInterval(messageHandler);
    });

    return {
      message,
    };
  },
});
</script>

<template>
  <div class="text-center">
    <base-icon class="text-primary mb-3" icon="fa-circle-notch fa-spin" size="fa-3x" />
    <h3 class="heading-2">{{ message }}</h3>
    <p>One moment while we retrieve your information</p>
  </div>
</template>

<style scoped>
</style>
