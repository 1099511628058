<script>
import { computed, defineComponent, reactive, ref } from '@vue/composition-api';
import BaseInput from '@/components/base/base-input.vue';
import BaseButton from '@/components/base/base-button.vue';
import BaseCheckbox from '@/components/base/base-checkbox.vue';

import ErrorNotification from '@/components/error-notification.vue';
import Notification from '@/components/notification.vue';
import PaymentArtifactHeader from '@/components/payment-artifact-header.vue';
import BaseIcon from '@/components/base/base-icon.vue';

import { ROUTE_LOGIN, ROUTE_REGISTER_CONFIRM_EMAIL, ROUTE_PAY } from '@/lib/router';
import { STATUS_CONFLICT } from '@/lib/api';
import { registerCustomerApi } from '@/api/registration';
import { parseToDate } from '@/lib/time';
import { useBrand } from '@/use/brand';
import { useRouter } from '@/use/router';
import { useApiErrors } from '@/use/errors';
import Logo from '@/components/logo.vue';

export default defineComponent({
  name: 'RegoCustomerCreateAccount',
  components: {
    BaseIcon,
    BaseInput,
    BaseButton,
    BaseCheckbox,
    ErrorNotification,
    Notification,
    Logo,
    PaymentArtifactHeader,
  },
  props: {
    artifactDetails: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const brand = useBrand();
    const router = useRouter();
    const isLoading = ref(false);
    const customerExists = ref(false);
    const form = reactive({
      firstName: '',
      lastName: '',
      email: '',
      dateOfBirth: '',
      password: '',
    });
    function renderErrorMessage(error) {
      const genericResponse = 'Something went wrong';
      if (!error?.response) {
        return genericResponse;
      }
      if (error?.response?.status === STATUS_CONFLICT) {
        customerExists.value = true;
        return '';
      }
      return error?.response?.data?.detail || genericResponse;
    }
    const { setError, clearError, apiError } = useApiErrors(renderErrorMessage);
    async function createUserAccount() {
      try {
        clearError();
        isLoading.value = true;
        const payload = { ...form, dateOfBirth: parseToDate(form.dateOfBirth) };
        await registerCustomerApi.postCustomerAccount(payload);
        await router.push({ name: ROUTE_REGISTER_CONFIRM_EMAIL, params: { email: form.email } });
      } catch (error) {
        setError(error);
      } finally {
        isLoading.value = false;
      }
    }
    const isError = computed(() => {
      if (apiError.value.title === 'Conflict') {
        return false;
      }
      return apiError.value.title !== '' || apiError.value.detail !== '';
    });
    function redirectBack() {
      router.push({ name: ROUTE_PAY, params: { artifactId: props.artifactDetails.artifactId } });
    }

    return {
      brand,
      form,
      customerExists,
      isLoading,
      isError,
      apiError,
      createUserAccount,
      ROUTE_LOGIN,
      redirectBack,
    };
  },
});
</script>

<template>
  <div class="customer-create-account">
    <div v-if="artifactDetails" class="flex flex-col-reverse md:flex-row justify-between items-center my-10">
      <span @click="redirectBack" data-testid="directBack" class="self-start md:self-auto cursor-pointer">
        <BaseIcon size="fa-md" class="back-icon" icon="fa-arrow-left"
      /></span>
      <PaymentArtifactHeader :artifactDetails="artifactDetails" class="w-11/12 justify-end" />
    </div>
    <Logo v-else class="my-10" size="small" />
    <h1 class="heading-2">Create an account</h1>
    <p class="text-sm text-shades-darker">Sign up and get approved in 5 minutes. Subject to terms and approval.</p>
    <form class="my-5 flex flex-col" @submit.prevent="createUserAccount">
      <div class="flex flex-col justify-between lg:flex-row">
        <BaseInput
          class="flex-grow lg:mr-2 mb-2"
          id="firstNameInput"
          v-model="form.firstName"
          required
          label="First Name"
          title="First name is required"
        />
        <BaseInput
          id="lastNameInput"
          class="flex-grow mb-2"
          v-model="form.lastName"
          required
          label="Last Name"
          title="Last name is required"
        />
      </div>
      <BaseInput
        class="mb-2"
        id="emailInput"
        v-model="form.email"
        required
        label="Email Address"
        type="email"
        title="Email is required"
      />
      <Notification variant="warning" class="mb-4 text-xs" v-show="customerExists">
        <p>
          An account already exists with this email - please
          <router-link class="text-link" :to="{ name: ROUTE_LOGIN }">log in</router-link> instead.
        </p>
      </Notification>
      <BaseInput
        class="mb-2"
        id="dateOfBirthInput"
        v-model="form.dateOfBirth"
        required
        label="Date Of Birth"
        icon="fa-calendar-alt"
        title="Please enter your date of birth"
        placeholder="DD/MM/YYYY"
        :formattingOptions="{
          date: true,
          datePattern: ['d', 'm', 'Y'],
        }"
      />
      <BaseInput
        class="mb-2"
        id="passwordInput"
        v-model="form.password"
        required
        type="password"
        label="Password"
        title="Password is required"
      />
      <p class="font-semibold">Additional information</p>
      <p class="text-xs text-shades-darker">
        Before you click "Create an account" make sure you have details of your residential history and bank login
        details to link your bank as we ask this in the next few steps.
      </p>
      <BaseCheckbox class="text-xs my-5" required>
        <p class="text-shades-darker">
          I agree to the Rapid Payment Plans
          <a class="text-link" target="_blank" :href="brand.customerTerms">Terms and Conditions</a> ("T&Cs"), and to my
          personal and credit information being collected, used, stored and disclosed in accordance with the
          <a class="text-link" target="_blank" :href="brand.privacy">Privacy Policy</a>.
        </p>
      </BaseCheckbox>
      <ErrorNotification class="mb-4" v-show="isError" type="is-warning" :errorMessages="apiError" />

      <BaseButton type="submit" :loading="isLoading" rounded> Create an account </BaseButton>
    </form>
    <p class="text-sm text-shades-darker">
      Have an existing account? <router-link class="text-link" :to="{ name: ROUTE_LOGIN }">Log in</router-link>
    </p>
  </div>
</template>

<style scoped>
</style>
