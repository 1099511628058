<script>
import { computed, defineComponent } from '@vue/composition-api';
import BaseIcon from '@/components/base/base-icon.vue';
import { BANK_STATUS_DEGRADED, BANK_STATUS_OPERATIONAL } from '@/use/bank-linking';

export default defineComponent({
  name: 'BankSelectTile',
  components: {
    BaseIcon,
  },
  props: {
    bank: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isOperational = computed(
      () => props.bank.status === BANK_STATUS_OPERATIONAL || props.bank.status === BANK_STATUS_DEGRADED,
    );
    function emitClick() {
      emit('click');
    }
    return {
      isOperational,
      emitClick,
    };
  },
});
</script>

<template>
  <figure class="bank-tile relative rounded flex flex-col justify-center" v-if="!isOperational">
    <img class="bank-logo" :src="bank.logo.links.square" :alt="bank.name" />
    <div class="absolute rounded rounded-b-none top-0 w-full flex justify-center items-center bg-shades-lighter py-1">
      <BaseIcon class="text-danger mr-1" icon="fa-exclamation-circle" size="fa-sm" />
      <div class="bank-banner text-shades-darker">
        <p class="font-bold">Temporarily Unavailable</p>
        <p class="">Try again later</p>
      </div>
    </div>
  </figure>

  <figure class="bank-tile flex justify-center items-center cursor-pointer" @click="emitClick" v-else>
    <img class="bank-logo" :src="bank.logo.links.square" :alt="bank.name" />
  </figure>
</template>

<style scoped>
.bank-banner {
  font-size: 10px;
}
.bank-tile {
  @apply h-36 w-36 border border-shades;
}
.bank-logo {
  max-height: 8.9rem;
  margin: 0.75rem;
}
</style>
