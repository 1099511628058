/**
 * base debounce function
 * @private
 * @param {Function} fn - function to be invoked
 * @param {number} waitMs - time to wait
 * @param {Object} options - function options
 * @param {boolean} options.throttle - converts debounce to a throttle
 * @returns {Function}
 */
function baseDebounce(fn, waitMs, options = { throttle: false }) {
  const wait = parseInt(waitMs, 10);
  let lastInvoked;
  let timer;

  function debounced(...args) {
    if (!lastInvoked) {
      lastInvoked = Date.now();
    }
    const lastThis = this;
    const timeNow = Date.now();
    const timeSinceLastInvoke = timeNow - lastInvoked;
    const waitRemaining = wait - timeSinceLastInvoke;
    let result = () => undefined;
    if (waitRemaining <= 0) {
      lastInvoked = timeNow;
      clearTimeout(timer);
      result = fn.apply(lastThis, args);
      return result;
    }

    if (!options.throttle) {
      lastInvoked = timeNow;
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      result = fn.apply(lastThis, args);
    }, wait);
    return result;
  }
  return debounced;
}

/**
 * Creates a throttled function that invokes after the provided time.
 * Any attempt to invoke the function before the wait will reset the timer
 * @param {composeFunction} fn function to be invoked
 * @param {Number} waitMs wait milliseconds
 * @returns {any}
 * @example
 * // foo should be 1
 * let foo = 0;
 * const bar = fns.debounce(() => {
 *    foo += 1;
 * }, 100);
 * bar();
 * bar();
 * await new Promise((r) => setTimeout(r, 110));
 */
export function debounce(fn, waitMs) {
  return baseDebounce(fn, waitMs, { throttle: false });
}

/**
 * invoke functions at most once per interval
 * @param {composeFunction} fn - function to be invoked
 * @param {Number} waitMs - wait in milliseconds
 * @returns {any}
 * @example
 * // count should be 2
 * let count = 0;
 * const bar = fns.throttle(() => {
 *     count += 1;
 * }, 100);
 * bar();
 * bar();
 * bar();
 * await new Promise((r) => setTimeout(r, 110));
 * bar();
 */
export function throttle(fn, waitMs) {
  return baseDebounce(fn, waitMs, { throttle: true });
}
