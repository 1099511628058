var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col max-w-lg mx-auto"},[_c('div',{staticClass:"relative"},[_c('a',{on:{"click":_vm.routeToCreateAccount}},[_c('BaseIcon',{staticClass:"absolute top-1/3 cursor-pointer md:hidden",attrs:{"icon":"fa-arrow-left","size":"fa-lg"}})],1),_c('h1',{staticClass:"heading-2 md:heading-1 text-center"},[_vm._v("Residential History")])]),_c('p',{staticClass:"py-4 text-sm text-shades-darker"},[_vm._v(" We need to collect some residential history information to verify your identity. ")]),_c('p',{staticClass:"font-semibold"},[_vm._v("Current Address")]),_c('form',{staticClass:"my-3 flex flex-col",on:{"submit":function($event){$event.preventDefault();return _vm.submitAddressHistory.apply(null, arguments)}}},[_c('AddressFinder',{staticClass:"mb-1",attrs:{"id":"currentAddress","required":"","name":"currentAddress","label":"Home address","title":"Please enter your current home address"},on:{"address-selected":_vm.updateCurrentAddress},model:{value:(_vm.form.currentAddress.rawAddress),callback:function ($$v) {_vm.$set(_vm.form.currentAddress, "rawAddress", $$v)},expression:"form.currentAddress.rawAddress"}}),_c('BaseInput',{attrs:{"required":"","id":"currentAddressMoveInDateInput","value":_vm.form.currentAddress.moveInDate,"label":"Move-In Date","icon":"fa-calendar-alt","title":"Please enter your current address move in date","placeholder":'MM/YYYY',"formattingOptions":{
        date: true,
        datePattern: ['m', 'Y'],
      }},on:{"input":_vm.updateCurrentAddressMoveInDate}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.previousAddressRequired),expression:"previousAddressRequired"}],staticClass:"flex flex-col"},[_c('p',{staticClass:"font-semibold"},[_vm._v("Previous Addresses")]),_c('p',{staticClass:"pb-2 text-xs text-shades-darker max-w-md"},[_vm._v(" Looks like you have lived at your current home for less than five years. Please add in your previous address/es for up to five years. ")]),_vm._l((_vm.form.previousAddress),function(address){return _c('div',{key:address.id},[_c('div',{staticClass:"relative"},[_c('a',{staticClass:"text-danger cursor-pointer absolute -top-1 right-0 text-sm",on:{"click":function($event){return _vm.removePreviousAddress(address.id)}}},[_vm._v(" remove ")]),_c('AddressFinder',{staticClass:"mt-4 w-full",attrs:{"id":address.id,"label":"Home address"},on:{"address-selected":_vm.updatePreviousAddress},model:{value:(address.rawAddress),callback:function ($$v) {_vm.$set(address, "rawAddress", $$v)},expression:"address.rawAddress"}})],1),_c('div',{staticClass:"flex flex-col justify-between md:flex-row"},[_c('BaseInput',{staticClass:"flex-grow md:mr-2",attrs:{"id":("previousMoveInDate" + (address.id)),"label":"Move-In Date","icon":"fa-calendar-alt","placeholder":'MM/YYYY',"formattingOptions":{
              date: true,
              datePattern: ['m', 'Y'],
            }},model:{value:(address.moveInDate),callback:function ($$v) {_vm.$set(address, "moveInDate", $$v)},expression:"address.moveInDate"}}),_c('BaseInput',{staticClass:"flex-grow",attrs:{"id":("previousMoveOutDate" + (address.id)),"label":"Move-Out Date","icon":"fa-calendar-alt","placeholder":'MM/YYYY',"formattingOptions":{
              date: true,
              datePattern: ['m', 'Y'],
            }},model:{value:(address.moveOutDate),callback:function ($$v) {_vm.$set(address, "moveOutDate", $$v)},expression:"address.moveOutDate"}})],1)])}),_c('BaseButton',{staticClass:"w-full my-4",attrs:{"outlined":"","rounded":""},on:{"click":function($event){$event.preventDefault();return _vm.addEmptyPreviousAddress.apply(null, arguments)}}},[_c('BaseIcon',{staticClass:"mr-2",attrs:{"icon":"fa-plus"}}),_c('span',{staticClass:"font-normal"},[_vm._v("Add another address")])],1)],2),_c('ErrorNotification',{directives:[{name:"show",rawName:"v-show",value:(_vm.isError),expression:"isError"}],attrs:{"type":"is-warning","errorMessages":_vm.apiError}}),_c('div',{staticClass:"flex justify-between mt-8"},[_c('BaseButton',{staticClass:"hidden md:block w-48",attrs:{"secondary":"","outlined":"","rounded":""},on:{"click":_vm.routeToCreateAccount}},[_vm._v(" Back ")]),_c('BaseButton',{staticClass:"w-full md:w-48",attrs:{"primary":"","rounded":"","disabled":_vm.buttonDisabled}},[_vm._v(" Confirm ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }