<script>
import { computed, defineComponent, ref } from '@vue/composition-api';
import SelectAccountCard from '@/components/select-account-card.vue';
import BaseButton from '@/components/base/base-button.vue';
import { useRouter } from '@/use/router';
import { ROUTE_CUSTOMER_DASHBOARD, ROUTE_PAY, ROUTE_REGISTER_LINK_BANK } from '@/lib/router';
import { useApiErrors } from '@/use/errors';
import { registerCustomerApi } from '@/api/registration';
import ErrorNotification from '@/components/error-notification.vue';
import { useBrand } from '@/use/brand';

export default defineComponent({
  name: 'RegoCustomerSelectAccount',
  components: {
    SelectAccountCard,
    BaseButton,
    ErrorNotification,
  },
  props: {
    bankName: {
      type: String,
      required: true,
    },
    accounts: {
      type: Array,
      required: false,
      default: () => [],
    },
    artifactId: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const router = useRouter();
    const brand = useBrand();
    const selectedAccount = ref('');
    const isLoading = ref(false);
    const isDisabled = computed(() => selectedAccount.value === '');

    const genericNotification = `There was an issue finalising your account, please get in contact on ${brand.contactPhone}`;

    const { apiError, isError, setError, clearError } = useApiErrors(null, genericNotification);

    const hasAccounts = computed(() => props.accounts.length > 0);

    function isAccountSelected(account) {
      return account?.token === selectedAccount.value;
    }

    function setSelectedAccount(token) {
      selectedAccount.value = token;
    }

    function routeToDashboardOrPaymentPending() {
      if (props.artifactId) {
        router.push({ name: ROUTE_PAY, params: { artifactId: props.artifactId } });
        return;
      }
      router.push({ name: ROUTE_CUSTOMER_DASHBOARD });
    }

    async function submitSelectedAccount() {
      try {
        isLoading.value = true;
        clearError();
        await registerCustomerApi.postSelectAccount(selectedAccount.value);
        routeToDashboardOrPaymentPending();
      } catch (error) {
        setError(error);
      } finally {
        isLoading.value = false;
      }
    }

    async function removeBankConnection() {
      try {
        isLoading.value = true;
        clearError();
        // waiting for response causing bad UX since it's suppose to behave like a "link"
        registerCustomerApi.deleteBankLink();
        router.push({ name: ROUTE_REGISTER_LINK_BANK });
      } catch (error) {
        setError(error);
      } finally {
        isLoading.value = false;
      }
    }

    return {
      hasAccounts,
      isAccountSelected,
      setSelectedAccount,
      submitSelectedAccount,
      removeBankConnection,
      isLoading,
      apiError,
      isError,
      isDisabled,
    };
  },
});
</script>

<template>
  <div class="max-w-lg mx-auto flex flex-col items-center">
    <h1 class="heading-1 mb-6">Select an account</h1>

    <p class="mb-4 text-shades-darker">
      Select one of the accounts connected to your <span class="font-bold">{{ bankName }}</span> login to use for your
      payments.
    </p>
    <p class="mb-4">
      Note that the account <span class="font-bold">must not</span> be a credit card, overdraft amount or any other
      borrowed money.
    </p>

    <template v-if="hasAccounts">
      <SelectAccountCard
        class="my-3"
        v-for="account in accounts"
        :key="account.token"
        :account="account"
        :selected="isAccountSelected(account)"
        @click="setSelectedAccount"
      />
      <ErrorNotification class="my-2" :errorMessages="apiError" v-show="isError" />
      <BaseButton :loading="isLoading" :disabled="isDisabled" class="mt-8" rounded @click="submitSelectedAccount">
        Complete registration
      </BaseButton>
      <a @click="removeBankConnection" class="text-link text-sm my-8">Log out and choose another bank</a>
    </template>

    <template v-else>
      <div class="text-center border border-shades rounded-md p-6 my-6 w-full">
        <h3 class="font-bold text-lg">No available accounts</h3>
        <p class="text-shades-darker">Please unlink this bank and choose another bank which has valid accounts.</p>
      </div>
      <BaseButton rounded class="my-6 w-full" @click="removeBankConnection">
        Unlink and choose another bank
      </BaseButton>
    </template>
  </div>
</template>

<style scoped>
</style>
