<script>
import { defineComponent, ref, watch } from '@vue/composition-api';
import { useBrand } from '@/use/brand';
import BankSelectTile from '@/components/bank-select-tile.vue';
import BaseModal from '@/components/base/base-modal.vue';
import BankLogin from '@/components/bank-login.vue';
import { useRouter } from '@/use/router';
import useBankLinking, { BANK_SYNC_COMPLETE } from '@/use/bank-linking';
import { ROUTE_REGISTER_CUSTOMER_SELECT_ACCOUNT, ROUTE_PAY } from '@/lib/router';

export default defineComponent({
  components: {
    BankSelectTile,
    BaseModal,
    BankLogin,
  },
  name: 'RegoCustomerLinkBank',
  props: {
    artifactId: {
      type: String,
      required: false,
    },
    banks: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup() {
    const waitBeforeRouteMs = 2000;
    const brand = useBrand();
    const router = useRouter();
    const { syncStatus, isLoading, apiError, isError, clearError, submitBankLogin } = useBankLinking(router);
    const expand = ref(false);
    const selectedBank = ref({
      id: '',
      name: '',
      shortName: '',
    });
    const showModal = ref(false);

    watch(syncStatus, (val) => {
      if (val === BANK_SYNC_COMPLETE) {
        setTimeout(() => {
          router.push({
            name: ROUTE_REGISTER_CUSTOMER_SELECT_ACCOUNT,
            params: {
              bankName: selectedBank.value.shortName,
            },
          });
        }, waitBeforeRouteMs);
      }
    });

    function closeModal() {
      clearError();
      showModal.value = false;
    }

    function expandList() {
      expand.value = true;
    }

    function selectBank(bank) {
      showModal.value = true;
      selectedBank.value = bank;
    }

    return {
      brand,
      isLoading,
      apiError,
      isError,
      selectedBank,
      expand,
      selectBank,
      submitBankLogin,
      syncStatus,
      showModal,
      closeModal,
      expandList,
      ROUTE_PAY,
    };
  },
});
</script>

<template>
  <div class="max-w-xl xl:max-w-screen-md mx-auto">
    <h1 class="heading-2 md:heading-1 text-center my-6">Link your Bank Account</h1>
    <p class="text-center text-shades-dark">
      Securely connect your preferred transactional bank with {{ brand.name }} to pay via fortnightly instalments
    </p>
    <h3 class="heading-3 md:heading-2 mt-10">Choose your bank</h3>
    <div :class="['bank-grid flex flex-wrap justify-around h-96 overflow-hidden ', { expand }]">
      <BankSelectTile
        @click="selectBank(bank)"
        class="m-0.5 md:m-3"
        v-for="bank in banks"
        :key="bank.id"
        :bank="bank"
      />
    </div>
    <div class="text-center">
      <a v-show="!expand" @click="expandList" class="text-link mt-2">see more</a>
    </div>
    <p class="text-xs text-shades-dark my-10 text-center">
      We use <a class="text-primary" :href="brand.openApiProviderUrl" target="_blank">{{ brand.openApiProvider }}</a> to
      securely connect to your bank account and provide a one-time verification of your account information. We do not
      hold or store any of your financial information and your credentials are never shared within the
      {{ brand.parentOrg }}.
    </p>
    <router-link v-if="artifactId" class="text-link" :to="{ name: ROUTE_PAY, params: { artifactId } }"
      >Cancel sign up and return to pay via card</router-link
    >
    <BaseModal v-focus-trap="true" :allowClickAway="false" :show="showModal">
      <BankLogin
        :error="apiError"
        :isError="isError"
        :bank="selectedBank"
        :isLoading="isLoading"
        :bankLinkStatus="syncStatus"
        @submit="submitBankLogin"
        @close="closeModal"
      />
    </BaseModal>
  </div>
</template>

<style scoped>
.bank-grid {
  box-shadow: inset 0 -10px 10px -10px rgba(0, 0, 0, 0.33);
}
.expand {
  @apply h-full overflow-visible shadow-none;
}
</style>
